<script>
export default {
  name: 'CaseCardLayout',
  props: {
    smCase: {
      type: Object,
      required: true,
    },
    trackingEvents: {
      type: Object,
      required: true,
    },
    showAllTrackingEvents: {
      type: Boolean,
      required: false,
    },
    maxEvents: {
      type: Number,
      required: false,
    },
  },
};
</script>

<template>
  <div class="card border-left-3 border-left-danger rounded-left-0">
    <div class="card-header bg-light header-elements-inline">
      <h1 class="card-title">
        <slot name="card-title"></slot>
      </h1>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6 mb-2">
          <slot name="card-info"></slot>
        </div>
        <div class="col-12 col-md-6">
          <h5 class="mb-1">Recent Events</h5>
          <ul>
            <template v-for="(value, index) in trackingEvents">
              <li v-if="showAllTrackingEvents || index < maxEvents" :key="index">
                {{ value.humanReadable }} - <span class="text-black-50">{{ value.created_at | fromNow }}</span>
              </li>
            </template>
          </ul>
          <slot name="toggle-events"></slot>
        </div>
      </div>
    </div>

    <div class="card-footer d-sm-flex justify-content-sm-between align-items-sm-left">
      <span class="updated" v-if="smCase.updated_at">
        <i class="icon-calendar2 mr-2"></i>
        Created <span class="font-weight-semibold">{{ smCase.created_at | fromNow }}</span> and last updated:
        <span class="font-weight-semibold">{{ smCase.updated_at | fromNow }}</span>
      </span>
      <span class="updated" v-else
        ><i class="icon-calendar2 mr-2"></i>Created
        <span class="font-weight-semibold">{{ smCase.created_at | fromNow }}</span></span
      >
      <ul class="list-inline mb-0 mt-2 mt-sm-0">
        <li class="list-inline-item">
          <slot name="card-destination"></slot>
        </li>
      </ul>
    </div>
  </div>
</template>
