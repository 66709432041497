export const PAYMENT_COMPLETED = 'completed_payment';
export const CLIENT_QUESTIONNAIRE_STARTED = 'started_questionnaire';
export const CLIENT_QUESTIONNAIRE_COMPLETED = 'completed_questionnaire';
export const CLIENT_QUESTIONNAIRE_SIGNED_COMPLETED = 'signed_questionnaire';
export const TA6_STARTED = 'started_ta6';
export const TA6_COMPLETED = 'completed_ta6';
export const TA7_STARTED = 'started_ta7';
export const TA7_COMPLETED = 'completed_ta7';
export const TA10_STARTED = 'started_ta10';
export const TA10_COMPLETED = 'completed_ta10';
export const TERMS_OF_BUSINESS_CONFIRMED = 'terms_of_business_confirmed';
export const TERMS_OF_BUSINESS_SIGNED = 'Terms_Of_Business_Signing_Complete';
export const TERMS_OF_ENGAGEMENT_CONFIRMED = 'terms_of_engagement_confirmed';
export const TERMS_OF_ENGAGEMENT_SIGNED = 'Terms_Of_Engagement_Signing_Complete';
export const CLIENT_CARE_PACK_SIGNED = 'Client_Care_Pack_Signing_Complete';
export const COMPLETED_AML_INDIVIDUAL = 'completed_aml_individual';
export const REMORTGAGE_CLIENT_QUESTIONNAIRE_SIGNED_COMPLETED = 'Remortgage_Questionnaire_Signing_Complete';
export const REMORTGAGE_CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL = 'Remortgage_Questionnaire_Signed_Individual';
export const CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL = 'client_questionnaire_signed_individual';
export const CLIENT_CARE_PACK_SIGNED_INDIVIDUAL = 'client_care_pack_signed_individual';
export const ID_CHECK_HOOYU_STARTED_INDIVIDUAL = 'started_hooyu_individual';
export const ID_CHECK_HOOYU_COMPLETE_INDIVIDUAL = 'completed_hooyu_individual';
export const ID_CHECK_HOOYU = 'completed_hooyu_all';
export const TA6_SIGNED_INDIVIDUAL = 'ta6_signed_individual';
export const TA6_SIGNING_COMPLETE = 'TA6_Signing_Complete';
export const TA7_SIGNED_INDIVIDUAL = 'ta7_signed_individual';
export const TA7_SIGNING_COMPLETE = 'TA7_Signing_Complete';
export const TA10_SIGNED_INDIVIDUAL = 'ta10_signed_individual';
export const TA10_SIGNING_COMPLETE = 'TA10_Signing_Complete';
export const SALE_QUESTIONNAIRE_SIGNING_COMPLETE = 'Sale_Questionnaire_Signing_Complete';
export const PURCHASE_QUESTIONNAIRE_SIGNING_COMPLETE = 'Purchase_Questionnaire_Signing_Complete';
export const HMLR_TRACKING_EVENT_STARTED = 'processing_hmlr_documents';
export const HMLR_TRACKING_EVENT_COMPLETED = 'completed_hmlr_documents';
export const UPLOAD_SUPPORTING_DOCUMENTS = 'upload_supporting_documents';
