<script>
export default {
  name: 'CaseErrorMessageCardLayout',
  props: {
    showError: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="card bg-light">
    <div class="card-body">
      <p class="text-danger">
        We encountered an error processing your {{ showError }}. Please check again later. If the issue persists, please
        use the 'Found a problem?' form located on the right hand side of the screen to get in touch. We apologise for
        any inconvenience.
      </p>
    </div>
  </div>
</template>
