import getBuilding from '@/api/buildings/getBuilding';
import getPerson from '@/api/persons/getPerson';
import getTrackingEvents from '@/api/trackingEvents/getTrackingEvents';
import trackingEventsHumanName from '@/values/trackingEventsHumanName';

export default {
  data() {
    return {
      clients: [],
      building: null,
      trackingEvents: {},
      maxEvents: 2,
      showAllTrackingEvents: false,
    };
  },
  methods: {
    numberOfTrackingEvents() {
      return Object.keys(this.trackingEvents).length;
    },
    toggleShowAllEvents() {
      this.showAllTrackingEvents = !this.showAllTrackingEvents;
    },
    async loadBuildingData() {
      if (!this.smCase.remortgage_id) {
        this.building = (await getBuilding(this.smCase.building_id)).data;
      }
    },
    async loadClientData() {
      const clientData = await Promise.all(this.smCase.client_ids.map((id) => getPerson(id)));
      this.client = clientData.map(({ data }) => data);
    },
    async loadTrackingData(case_id, trackingEventsRequired) {
      const res = await getTrackingEvents(case_id, trackingEventsRequired);
      this.trackingEvents = {
        ...res.data.results.map((event) => {
          const humanReadable = trackingEventsHumanName[event.event_slug];
          return { ...event, humanReadable };
        }),
      };
    },
    async loadCaseData(case_id, trackingEventsRequired) {
      await Promise.all([
        this.loadBuildingData(),
        this.loadClientData(),
        this.loadTrackingData(case_id, trackingEventsRequired),
      ]);
      if (this.smCase.hasOwnProperty('supplier_information') && this.smCase.supplier_information) {
        this.smCase.supplier_information = JSON.parse(JSON.stringify(this.smCase.supplier_information));
      }
    },
  },
};
