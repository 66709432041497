<script>
import Loader from '@/components/Loader';
import CaseCard from '@/mixins/CaseCard';
import CaseCardLayout from '@/components/CaseCardLayout';
import CaseErrorMessageCardLayout from '@/components/messaging/CaseErrorMessageCardLayout';

export default {
  name: 'CaseCardRemortgage',
  mixins: [CaseCard],
  components: { CaseErrorMessageCardLayout, Loader, CaseCardLayout },
  props: {
    smCase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      showError: false,
    };
  },
  mounted() {
    this.loadCaseData(this.smCase.remortgage_id, this.trackingEventsRequired)
      .then(() => (this.loading = false))
      .catch((err) => {
        this.loading = false;
        this.showError = 'remortgage case(s)';
        console.error(err);
      });
  },
  computed: {
    trackingEventsRequired: function () {
      const trackingEvents = ['started_questionnaire', 'completed_questionnaire'];
      return trackingEvents;
    },
  },
};
</script>

<template>
  <div v-if="loading" class="card-body">
    <loader>Loading Remortgage Cases...</loader>
  </div>
  <case-error-message-card-layout v-else-if="showError" :showError="showError" />
  <div v-else>
    <case-card-layout
      :sm-case="this.smCase"
      :max-events="this.maxEvents"
      :show-all-tracking-events="this.showAllTrackingEvents"
      :tracking-events="this.trackingEvents"
    >
      <template slot="card-title">
        <router-link
          class="text-default"
          :to="{
            name: 'ViewEntity',
            params: {
              entityType: 'remortgage',
              entityId: smCase.remortgage_id,
              thoroughfare:
                smCase.address && smCase.address.hasOwnProperty('thoroughfare') ? smCase.address.thoroughfare : '',
              postal_code:
                smCase.address && smCase.address.hasOwnProperty('postal_code') ? smCase.address.postal_code : '',
            },
          }"
        >
          {{ smCase.standalone_toe ? 'Transfer Of Equity for' : 'Remortgage of' }}
          {{ smCase.address.premise }} {{ smCase.address.thoroughfare }}, {{ smCase.address.postal_code }} ({{
            this.smCase.sort_refer_id
          }})
        </router-link>
      </template>

      <template slot="card-info">
        <h5 class="mb-1">Clients</h5>
        <ul class="media-list">
          <li v-for="(client, index) in clients" :key="index" class="text-capitalize">
            <i class="icon-person mr-2" />{{ client.title }} {{ client.first_name }} {{ client.last_name }}
          </li>
        </ul>

        <h5 class="mt-3 mb-1">Case Details:</h5>
        <span class="d-block"
          ><i class="icon-folder-search mr-2"></i>Reference Number: {{ this.smCase.sort_refer_id }}</span
        >

        <template v-if="this.smCase.supplier_information">
          <h5 class="mt-3 mb-1">Solicitors</h5>
          <span class="d-block"><i class="icon-briefcase mr-2"></i>{{ this.smCase.supplier_information.name }}</span>
          <span class="d-block"><i class="icon-phone mr-2"></i>{{ this.smCase.supplier_information.phone }}</span>
        </template>
      </template>

      <template slot="toggle-events">
        <a href="javascript:void(0);" @click="toggleShowAllEvents" v-if="numberOfTrackingEvents > maxEvents">
          <span v-if="showAllTrackingEvents">Hide additional events</span>
          <span v-else>Show all events</span>
        </a>
      </template>

      <template slot="card-destination">
        <router-link
          class="text-default"
          :to="{
            name: 'ViewEntity',
            params: {
              entityType: 'remortgage',
              entityId: smCase.remortgage_id,
              thoroughfare:
                smCase.address && smCase.address.hasOwnProperty('thoroughfare') ? smCase.address.thoroughfare : '',
              postal_code:
                smCase.address && smCase.address.hasOwnProperty('postal_code') ? smCase.address.postal_code : '',
            },
          }"
        >
          <button class="btn bg-primary btm-sm bg-info mr-1">
            <i class="icon-enter3 mr-2"></i>
            Open
          </button>
        </router-link>
      </template>
    </case-card-layout>
  </div>
</template>
