var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"card-body"},[_c('loader',[_vm._v("Loading Purchase Cases...")])],1):(_vm.showError)?_c('case-error-message-card-layout',{attrs:{"showError":_vm.showError}}):_c('div',[_c('case-card-layout',{attrs:{"sm-case":this.smCase,"max-events":this.maxEvents,"show-all-tracking-events":this.showAllTrackingEvents,"tracking-events":this.trackingEvents}},[_c('template',{slot:"card-title"},[_c('router-link',{staticClass:"text-default",attrs:{"to":{
          name: 'ViewEntity',
          params: {
            entityType: 'purchase',
            entityId: _vm.smCase.purchase_id,
            thoroughfare: _vm.building.address.thoroughfare,
            postal_code: _vm.building.address.postal_code,
          },
        }}},[_vm._v(" Purchase of "+_vm._s(_vm.building.address.premise)+" "+_vm._s(_vm.building.address.thoroughfare)+", "+_vm._s(_vm.building.address.postal_code)+" ("+_vm._s(this.smCase.sort_refer_id)+") ")])],1),_c('template',{slot:"card-info"},[_c('h5',{staticClass:"mb-1"},[_vm._v("Clients")]),_c('ul',{staticClass:"media-list"},_vm._l((_vm.clients),function(client,index){return _c('li',{key:index,staticClass:"text-capitalize"},[_c('i',{staticClass:"icon-person mr-2"}),_vm._v(_vm._s(client.title)+" "+_vm._s(client.first_name)+" "+_vm._s(client.last_name)+" ")])}),0),_c('h5',{staticClass:"mt-3 mb-1"},[_vm._v("Case Details:")]),_c('span',{staticClass:"d-block"},[_c('i',{staticClass:"icon-folder-search mr-2"}),_vm._v("Reference Number: "+_vm._s(this.smCase.sort_refer_id))]),(this.supplierInformation)?[_c('h5',{staticClass:"mt-3 mb-1"},[_vm._v("Solicitors")]),_c('span',{staticClass:"d-block"},[_c('i',{staticClass:"icon-briefcase mr-2"}),_vm._v(_vm._s(this.supplierInformation.name))]),_c('span',{staticClass:"d-block"},[_c('i',{staticClass:"icon-phone mr-2"}),_vm._v(_vm._s(this.supplierInformation.phone))])]:_vm._e()],2),_c('template',{slot:"toggle-events"},[(_vm.numberOfTrackingEvents > _vm.maxEvents)?_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":_vm.toggleShowAllEvents}},[(_vm.showAllTrackingEvents)?_c('span',[_vm._v("Hide additional events")]):_c('span',[_vm._v("Show all events")])]):_vm._e()]),_c('template',{slot:"card-destination"},[_c('router-link',{staticClass:"text-default",attrs:{"to":{
          name: 'ViewEntity',
          params: {
            entityType: 'purchase',
            entityId: _vm.smCase.purchase_id,
            thoroughfare: _vm.building.address.thoroughfare,
            postal_code: _vm.building.address.postal_code,
          },
        }}},[_c('button',{staticClass:"btn bg-primary btm-sm bg-info mr-1"},[_c('i',{staticClass:"icon-enter3 mr-2"}),_vm._v(" Open ")])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }