<script>
import Loader from '@/components/Loader.vue';
import SaleCaseCard from '@/components/SaleCaseCard';
import PurchaseCaseCard from '@/components/PurchaseCaseCard';
import RemortgageCaseCard from '@/components/RemortgageCaseCard';
import CaseErrorMessageCardLayout from '@/components/messaging/CaseErrorMessageCardLayout';
import { mapGetters } from 'vuex';
import loadAllCasesForUser, { DEFAULT_CASES } from '@/functions/loadAllCasesForUser';

export default {
  name: 'MyCases',
  components: { SaleCaseCard, PurchaseCaseCard, RemortgageCaseCard, CaseErrorMessageCardLayout, Loader },
  data() {
    return {
      loading: true,
      persons: [],
      cases: {},
      showError: false,
      cssProps: {
        backgroundImage: `url(${require('@/assets/sortmove-bricky.png')})`,
        backgroundSize: '300px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        paddingBottom: '320px',
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['cognitoUserId']),
  },
  async mounted() {
    const res = await loadAllCasesForUser(this.cognitoUserId).catch((err) => {
      this.showError = 'case(s)';
      console.error(err);
      return DEFAULT_CASES;
    });
    this.cases = res?.results;
    this.loading = false;
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-9">
        <loader v-if="loading">Checking Your Cases...</loader>
        <div v-else-if="cases.length !== 0">
          <div v-for="(smCase, index) in cases" :key="index" class="row">
            <sale-case-card v-if="smCase.sale_id" :sm-case="smCase" class="w-100 mr-2"></sale-case-card>
            <purchase-case-card v-if="smCase.purchase_id" :sm-case="smCase" class="w-100 mr-2"></purchase-case-card>
            <remortgage-case-card
              v-if="smCase.remortgage_id"
              :sm-case="smCase"
              class="w-100 mr-2"
            ></remortgage-case-card>
            <case-error-message-card-layout v-if="showError" :showError="showError" />
          </div>
        </div>
        <div v-else-if="showError">
          <div class="row">
            <case-error-message-card-layout :showError="showError" />
          </div>
        </div>
        <div v-else>
          <div class="col-12 text-center">
            <div class="card border-left-3 border-left-danger rounded-left-0">
              <div class="card-header bg-light header-elements-inline">
                <div class="card-body">
                  <span class="text-muted">Your account does not have any active cases.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="card bg-light">
          <div class="card-header header-elements-inline"><h3 class="card-title">Your Cases</h3></div>
          <div class="card-body">
            <p>
              This page provides an overview of each of your cases with us, whether they're current or historic. Each
              displays a completion percentage and some key information (last update etc).
            </p>
          </div>
          <div :style="cssProps"></div>
        </div>
      </div>
    </div>
  </div>
</template>
