import {
  CLIENT_CARE_PACK_SIGNED,
  CLIENT_CARE_PACK_SIGNED_INDIVIDUAL,
  CLIENT_QUESTIONNAIRE_COMPLETED,
  CLIENT_QUESTIONNAIRE_SIGNED_COMPLETED,
  CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL,
  CLIENT_QUESTIONNAIRE_STARTED,
  COMPLETED_AML_INDIVIDUAL,
  ID_CHECK_HOOYU,
  PAYMENT_COMPLETED,
  REMORTGAGE_CLIENT_QUESTIONNAIRE_SIGNED_COMPLETED,
  REMORTGAGE_CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL,
  TA10_COMPLETED,
  TA10_STARTED,
  TA10_SIGNED_INDIVIDUAL,
  TA6_COMPLETED,
  TA6_STARTED,
  TA6_SIGNED_INDIVIDUAL,
  TA7_COMPLETED,
  TA7_STARTED,
  TA7_SIGNED_INDIVIDUAL,
  TERMS_OF_BUSINESS_CONFIRMED,
  TERMS_OF_BUSINESS_SIGNED,
  TERMS_OF_ENGAGEMENT_CONFIRMED,
  TERMS_OF_ENGAGEMENT_SIGNED,
  UPLOAD_SUPPORTING_DOCUMENTS,
} from '@/values/trackingEvents';

const trackingEventsHumanName = {
  [TERMS_OF_BUSINESS_CONFIRMED]: 'Terms of Business accepted',
  [TERMS_OF_BUSINESS_SIGNED]: 'All parties signed Terms of Business',
  [TERMS_OF_ENGAGEMENT_CONFIRMED]: 'Terms of Engagement accepted',
  [TERMS_OF_ENGAGEMENT_SIGNED]: 'All parties signed Terms of Engagement',
  [CLIENT_CARE_PACK_SIGNED]: 'All parties signed Client Care Pack',
  [COMPLETED_AML_INDIVIDUAL]: 'ID/AML Check for ',
  [ID_CHECK_HOOYU]: 'ID/AML Check for ',
  [PAYMENT_COMPLETED]: 'Payment completed',
  [CLIENT_QUESTIONNAIRE_STARTED]: 'Client questionnaire started',
  [CLIENT_QUESTIONNAIRE_COMPLETED]: 'Client questionnaire completed',
  [CLIENT_QUESTIONNAIRE_SIGNED_COMPLETED]: 'All parties signed Client Questionnaire',
  [CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL]: 'Client Questionnaire Signed By ',
  [TA6_STARTED]: 'Property information form started',
  [TA6_COMPLETED]: 'Property information form completed',
  [TA6_SIGNED_INDIVIDUAL]: 'Property Information Signed By ',
  [TA7_STARTED]: 'Leasehold information form started',
  [TA7_COMPLETED]: 'Leasehold information form completed',
  [TA7_SIGNED_INDIVIDUAL]: 'Leasehold Information Signed By ',
  [TA10_STARTED]: 'Fixtures and fittings form started',
  [TA10_COMPLETED]: 'Fixtures and fittings form completed',
  [TA10_SIGNED_INDIVIDUAL]: 'Fixtures and Fittings Signed By ',
  [REMORTGAGE_CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL]: 'Client Questionnaire Signed By ',
  [REMORTGAGE_CLIENT_QUESTIONNAIRE_SIGNED_COMPLETED]: 'All parties signed Client Questionnaire',
  [CLIENT_QUESTIONNAIRE_SIGNED_INDIVIDUAL]: 'Client Questionnaire Signed By ',
  [CLIENT_CARE_PACK_SIGNED_INDIVIDUAL]: 'Client Care Pack Signed By ',
  [UPLOAD_SUPPORTING_DOCUMENTS]: 'Optional documents upload started',
};

export default trackingEventsHumanName;
